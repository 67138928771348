

















import Vue from 'vue'
import { ContentTree, IAdapterCMS } from '../types'

export default Vue.extend({
  name: 'CMSRenderer',

  provide () {
    const provideData = {
      adapter: this.adapter
    }

    Object.defineProperty(provideData, 'rootContent', {
      enumerable: true,
      get: () => this.root
    })

    return provideData
  },

  props: {
    root: Object as () => ContentTree,
    adapter: Object as () => IAdapterCMS | undefined,
    screenPreview: Boolean
  },

  created () {
    this.$emit('rendered')
  }
})
