import Vue, { CreateElement, VNode } from 'vue'
import { appOptions } from '@simpl/core/init-app'
import { apolloProvider } from '@simpl/core/plugins/apollo'
import i18n from '@simpl/core/plugins/i18n'

export default Vue.extend({
  name: 'IFrameWrapper',

  beforeUpdate () {
    if (!(this as any)._app) return;
    (this as any)._app.children = Object.freeze(this.$slots.default)
  },
  beforeDestroy () {
    const { _styleMutationObserver } = (this as any)
    if (_styleMutationObserver) _styleMutationObserver.disconnect()

    if ((this as any)._app) {
      (this as any)._app.$destroy()
    }
  },

  methods: {
    renderChildren () {
      const children = this.$slots.default
      const iFrameDocument = (this.$el as HTMLIFrameElement).contentDocument!
      const body = iFrameDocument.body
      const el = document.createElement('div')
      body.appendChild(el)

      /* body.addEventListener('dragover', (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
      }) */

      const iFrameApp = new Vue({
        name: 'IFrameWrapperApp',

        i18n,
        store: appOptions.store,
        vuetify: appOptions.vuetify,
        apolloProvider,

        data: { children: [] as readonly VNode[] | undefined },

        mounted () {
          this.children = Object.freeze(children)
        },

        render (h: CreateElement): VNode {
          return h('div', this.children as any)
        }
      })

      iFrameApp.$mount(el) // mount into iframe

      ;(this as any)._app = iFrameApp // cache instance for later updates

      if (module.hot) {
        (this as any)._styleMutationObserver = new MutationObserver(
          () => setTimeout(this.updateStyles, 3000)
        )
        ;(this as any)._styleMutationObserver.observe(document.head, {
          childList: true
        })
      }

      this.updateStyles()
    },

    updateStyles () {
      const iFrameDocument = (this.$el as HTMLIFrameElement).contentDocument!

      if (!iFrameDocument) return

      iFrameDocument.head.innerText = ''

      for (const el of document.head.querySelectorAll('style, link[rel=stylesheet]')) {
        const clone = el.cloneNode(true)
        iFrameDocument.head.appendChild(clone)
      }
    }
  },

  render (h: CreateElement): VNode {
    return h('iframe', {
      on: { load: () => this.renderChildren() }
    })
  }
})
