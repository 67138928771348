import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { v4 as uuid } from 'uuid'
import { scheduleUpdateQueue } from './update-queue'
import { Text } from '@simpl/core/types/graphql'
import { getTreeContent } from '@simpl/cms/utils/tree-operations'

export default function createSiteFromPreset (
  context: Vue,
  parentId: number | string,
  type: string,
  siblingId?: number | string,
  texts: Text[] = [],
  presetContent?: ContentTree[],
  insertBefore = false
): ContentTree {
  const parent = getTreeContent(context.$store.state.cms.content, parentId) as ContentTree
  const newContentObj = createNewSiteObject(type, parent.id, texts, presetContent)

  if (siblingId && !insertBefore) {
    const insertPos = parent.children.findIndex((c) => String(c.id) === String(siblingId))
    parent.children.splice(insertPos === -1 ? 0 : insertPos + 1, 0, newContentObj)
  } else if (siblingId && insertBefore) {
    const insertPos = parent.children.findIndex((c) => String(c.id) === String(siblingId))
    parent.children.splice(insertPos === -1 ? 0 : insertPos, 0, newContentObj)
  } else {
    if (!parent.children) Vue.set(parent, 'children', [])

    parent.children.push(newContentObj)
  }

  parseTextBeforeCreatingPreset(context, newContentObj)

  scheduleUpdateQueue()

  return newContentObj
}

function parseTextBeforeCreatingPreset (context: Vue, newContentObj: ContentTree) {
  newContentObj.children.forEach((child: ContentTree) => {
    child.texts = child.texts.filter((text: Text) => text.languagecode === context.$store.state.cms.currentLanguageCode)

    if (child.children.length) {
      parseTextBeforeCreatingPreset(context, child)
    }
  })
}

function createNewSiteObject (
  type: string,
  parentId: number | string,
  texts: Text[],
  presetContent?: ContentTree[]
): ContentTree {
  const result = {
    id: uuid(),
    type,
    isLocal: true,
    data: null,
    parent: null!,
    children: [],
    properties: null!,
    texts
  } as ContentTree

  if (presetContent) {
    result.children = presetContent.map((c) =>
      createNewComponentObject(c, result.id))
  }

  return result
}

function createNewComponentObject (
  component: ContentTree,
  parentId: number | string
): ContentTree {
  const result = {
    id: uuid(),
    type: 'component',
    isLocal: true,
    parent: {
      id: String(parentId as any)
    } as any,
    data: {
      tag: component.data!.tag,
      properties: component.data!.properties
    },
    children: [],
    properties: null!,
    texts: component.texts || []
  } as ContentTree

  if (component.children.length) {
    result.children = component.children.map((c) =>
      createNewComponentObject(c, result.id))
  }

  return result
}
