



























































import Vue from 'vue'

import {
  updateTargetTexts,
  createTextWithFallback,
  getTextForUserLanguage,
  getTextForLanguage
} from '@simpl/core/utils'

import createChapterOrSite from '@simpl/cms-components/utils/create-chapter-or-site'
import createSiteFromPreset from '@simpl/cms-components/utils/create-site-from-preset'
import removeContent from '@simpl/cms-components/utils/remove-content'
import { scheduleUpdateQueue } from '@simpl/cms-components/utils/update-queue'

import StructureGroup from './StructureGroup.vue'
import EditItemDialog from './EditItemDialog.vue'

import { ContentTree } from '../../../types'
import { MUTATIONS } from '../../../store/consts'
import { getTreeContent } from '../../../utils/tree-operations'
import { preparePresetContent } from '../../../utils/prepare-preset-content'

import { CREATE_CONTENT_PRESET, UPDATE_CONTENT_PRESET } from '../../../graphql'
import SortableJS from 'sortablejs'
import { mapMutations } from 'vuex'
import { ContentPreset, NewContentPresetInput, UpdateContentPresetInput } from '@simpl/core/types/graphql'
import { deleteConfirm } from '@simpl/core/utils/message'

type EditItemDialogResult = {
  name: string
  preset?: ContentTree
  savePresetTexts?: boolean
  selectedPreset?: ContentPreset
  thumbnailMobile: string
  thumbnailDesktop: string
  presetTagIds?: number[]
}

export default Vue.extend({
  name: 'StructureView',

  components: {
    StructureGroup,
    EditItemDialog
  },

  provide (this: any) {
    return {
      deleteContent: this.deleteContent,
      rename: this.rename,
      savePreset: this.savePreset,
      addScreen: this.addScreen
    }
  },

  model: {},

  props: {
    contentUpdating: Boolean,
    value: Boolean
  },

  data () {
    return {
      showAddSiteDialog: false,

      oldContentName: null! as string,
      editedContentType: null! as string,

      edit: false,

      editDialogResolve: null as null | ((value?: EditItemDialogResult) => void),

      savePresetTexts: false,
      savePresetSiteCache: null! as ContentTree,
      selectedPresetTagIds: [] as string[],
      selectedTagId: null! as string
    }
  },

  computed: {
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    valueProxy: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    moduleId () : number {
      return this.$store.state.cms.moduleId
    },
    contentTree (): ContentTree {
      this.$nextTick(this.createSortable)
      return this.$store.state.cms.content
    },
    expandedItems: {
      get (): (string | number)[] {
        return this.$store.state.cms.sidebarExpandedItems || []
      },
      set (values: (string | number)[]): void {
        if (this.contentUpdating) return
        this.$store.commit(`cms/${MUTATIONS.SET_SIDEBAR_EXPANDED_ITEMS}`, values)
      }
    },
    loading: {
      get (): number {
        return this.$store.state.cms._loading
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_LOADING_VALUE}`, v)
      }
    },
    renameDialogTitle (): string {
      return this.$t(
        `cms.structure.${this.edit ? 'rename' : 'create'}Title`,
        [
          this.$t(`cms.structure.${this.editedContentType || 'chapter'}`),
          this.oldContentName
        ]
      ) as string
    }
  },

  watch: {
    valueProxy (v: boolean) {
      if (v && !this.$store.state.cms.sidebarExpandedItems) {
        this.expandedItems = this.contentTree.children.map((child: ContentTree) => child.id)
      }
    },
    contentUpdating (v: boolean) {
      if (!v) {
        this.cancelRenaming()
      }
    },
    value () {
      if (!this.value) return

      window.setTimeout(() => {
        (this.$refs.navigationDrawer as any).callUpdate()
      })
    }
  },

  methods: {
    ...mapMutations('cms', ['setStructureChapterDragActive']),

    getTextForUserLanguage,
    getTreeContent,

    async showEditItemDialogAndWait (): Promise<EditItemDialogResult | undefined> {
      return new Promise(resolve => {
        this.editDialogResolve = resolve
        this.showAddSiteDialog = true
      })
    },

    async onDialogSave (result: EditItemDialogResult, done: () => void) {
      await this.editDialogResolve?.(result)
      done()
    },

    async onDialogCancel (done: () => void) {
      await this.editDialogResolve?.()
      done()
    },

    async addScreen (parent: ContentTree) {
      this.editedContentType = 'site'
      this.edit = false

      const value = await this.showEditItemDialogAndWait()

      if (!value) {
        return
      }

      const { name, preset } = value
      const texts = createTextWithFallback(name, null, 'display_name', this.$store.state.cms.currentLanguageCode) as any

      const newScreen = await createSiteFromPreset(
        this, parent.id, this.editedContentType,
        undefined,
        texts, preset?.children
      )

      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, newScreen.id)
    },
    async addChapter () {
      this.editedContentType = 'chapter'
      this.edit = false

      const value = await this.showEditItemDialogAndWait()

      if (!value) {
        return
      }

      const { name } = value

      createChapterOrSite(
        this, this.contentTree.id, this.editedContentType,
        undefined,
        createTextWithFallback(name, null, 'display_name', this.$store.state.cms.currentLanguageCode) as any
      )
    },
    async savePreset (siteId: string) {
      this.savePresetSiteCache = this.getTreeContent(this.contentTree, siteId) as ContentTree
      this.oldContentName = `${getTextForLanguage(this.savePresetSiteCache, this.$store.state.cms.currentLanguageCode)} ${this.$t('cms.presets.preset')}`
      this.editedContentType = 'preset'

      const value = await this.showEditItemDialogAndWait()

      if (!value) {
        this.savePresetSiteCache = null! as ContentTree
        return
      }

      const { name, preset, savePresetTexts, presetTagIds, thumbnailMobile, thumbnailDesktop } = value

      const presetContent = preparePresetContent(
        JSON.parse(JSON.stringify(this.savePresetSiteCache.children)),
        !!savePresetTexts
      )

      const variables = {
        domain_id: this.$store.state.auth.user.active_domain.id,
        type: this.savePresetSiteCache.type,
        data: JSON.stringify(presetContent),
        thumb_desktop: thumbnailDesktop,
        thumb_mobile: thumbnailMobile,
        texts: {
          create: createTextWithFallback(name, null, 'display_name', this.$store.state.cms.currentLanguageCode)
        },
        tags: {
          sync: presetTagIds
        }
      } as NewContentPresetInput | UpdateContentPresetInput

      if (preset) {
        (variables as UpdateContentPresetInput).id = preset.id
      }

      await this.$apollo.mutate({
        mutation: preset ? UPDATE_CONTENT_PRESET : CREATE_CONTENT_PRESET,

        variables: {
          data: variables
        }
      })

      this.$notification.publish('bottom', {
        message: this.$t('cms.presets.presetCreated'),
        type: 'success',
        color: 'success'
      })
    },
    async rename (content: ContentTree) {
      this.oldContentName = getTextForLanguage(content, this.$store.state.cms.currentLanguageCode)
      this.editedContentType = content.type
      this.edit = true

      const value = await this.showEditItemDialogAndWait()

      if (!value) { return }

      const { name } = value

      updateTargetTexts(content, name, 'display_name', this.$store.state.cms.currentLanguageCode)
      scheduleUpdateQueue()
    },
    deleteContent (content: ContentTree) {
      deleteConfirm(
        this,
        content.texts
          .filter(({ languagecode }) =>
            (languagecode === this.$store.state.cms.currentLanguageCode)
          )[0]?.text || '')
        .then((answer) => {
          if (!answer) return
          removeContent(content.id)
          if (this.$store.state.cms.selectedSiteId === content.id) {
            this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, null)
          }
        })
    },

    cancelRenaming () {
      window.setTimeout(() => {
        this.showAddSiteDialog = false
        this.oldContentName = null!
        this.editedContentType = null!
        this.selectedTagId = null!
        this.edit = false
      }, 1000)
    },

    setExpanded (id: number, open: boolean) {
      if (open) {
        if (this.expandedItems.includes(id)) return

        this.expandedItems = [...this.expandedItems, id]
      } else {
        this.expandedItems = this.expandedItems.filter(c => String(c) !== String(id))
      }
    },

    createSortable () {
      if ((this as any)._sortable) {
        (this as any)._sortable.destroy()
      }

      if (!this.contentTree) return

      const ref = this.$refs.chapterContainer as HTMLElement
      (this as any)._sortable = new SortableJS(ref, {
        handle: '.drag-handle--group',
        dragClass: 'structure-group--ghost',
        animation: 150,
        group: {
          name: 'chapter'
        },
        onUpdate: evt => {
          const chapters = this.contentTree.children
          const { oldIndex, newIndex } = evt
          chapters.splice(newIndex!, 0, chapters.splice(oldIndex!, 1)[0])
          scheduleUpdateQueue()
        },
        onStart: () => {
          this.setStructureChapterDragActive(true)
        },
        onEnd: () => {
          this.setStructureChapterDragActive(false)
        }
      })
    }
  }
})
