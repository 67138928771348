







































































































import Vue from 'vue'
import { ContentTree } from '../../../types'
import { capitalize } from '@simpl/core/utils'
import { MUTATIONS } from '../../../store/consts'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

export default Vue.extend({
  inject: {
    deleteContent: { default: () => {} },
    rename: { default: () => {} },
    savePreset: { default: () => {} }
  },

  props: {
    content: Object as () => ContentTree,
    backgroundOpacity: {
      type: Number,
      default: 0.3
    }
  },

  computed: {
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    title (): string {
      return getTextForLanguageWithFallback(this.content, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode)
    },
    isCurrentSite (): boolean {
      return this.$store.state.cms?.selectedSiteId === this.content.id
    }
  },

  methods: {
    capitalize,
    select (): void {
      this.$store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, this.content.id)
    }
  }
})
