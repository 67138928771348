import { LIST_TAGS } from '../../graphql'
import Vue from 'vue'
import { getTextForUserLanguage } from '../../utils'
import { Query, Tag } from '../../types/graphql'

export default Vue.extend({
  data: () => ({
    contentPresetTags: [] as Tag[],
    contentPresetTagCategoryId: null! as string
  }),

  computed: {
    contentPresetTagIds (): string[] {
      return this.contentPresetTags.map((item: { id: string }) => item.id)
    }
  },

  apollo: {
    contentPresetTags: {
      query: LIST_TAGS,

      variables (): object {
        return {
          first: 9999,
          filter: {
            filterBy: [{
              name: 'identifier',
              values: ['content-preset']
            }]
          }
        }
      },

      update (result: Query): any[] {
        this.contentPresetTagCategoryId = result.tagCategories!.data[0].id

        const tagCategories = result.tagCategories!
        const tags = tagCategories.data[0]?.tags || []
        return tags!.slice().map((r: any) => ({
          ...r!,
          name: getTextForUserLanguage(r!)
        }))
      },

      error (error: Error) {
        console.error(error)
      }
    }
  },

  methods: {
    refetch () {
      this.$apollo.queries.contentPresetTags.refetch()
    }
  }
})
