
























































































































































import ScreenPreview from '../../ScreenPreview.vue'

import ContentPresetTags from '@simpl/core/mixins/apollo/ContentPresetTags'

import { ContentTree } from '../../../types'
import { ContentPreset } from '@simpl/core/types/graphql'
import PresetsPreview from './PresetsPreview.vue'
import mixins from 'vue-typed-mixins'
import html2canvas from 'html2canvas'

type ContentTreeAndPreset = ContentTree & ContentPreset

export default mixins(ContentPresetTags).extend({
  name: 'EditItemDialog',

  components: {
    ScreenPreview,
    PresetsPreview
  },

  model: {},

  props: {
    value: Boolean,
    isSite: Boolean,
    isPreset: Boolean,
    edit: Boolean,
    oldTitle: String,
    presetSiteCache: Object as () => ContentTree
  },

  data () {
    return {
      step: 0,
      name: '',
      selectedPreset: null as ContentTreeAndPreset | null,
      selectedTagId: null as null | string,
      savePresetTexts: true,
      presetTagIds: [] as string[],
      presetDesktopThumb: 'data:image/png;',
      presetMobileThumb: 'data:image/png;',
      thumbnailsGenerated: false
    }
  },

  computed: {
    valueProxy: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    pagination (): string {
      return `<span class="font-weight-bold">${this.step + 1}</span>` + ' | 2'
    },
    title (): string {
      if (this.isPreset) {
        return this.$t('cms.structure.saveAsNewPreset') as string
      }

      return this.$t(
        `cms.structure.${this.edit ? 'rename' : 'create'}Title`,
        [this.$t(`cms.structure.${this.isSite ? 'site' : 'chapter'}`)]
      ) as string
    }
  },

  watch: {
    async value (v: boolean) {
      if (!v) {
        this.thumbnailsGenerated = false
        return
      }

      this.name = (this.edit || this.isPreset)
        ? this.oldTitle
        : this.$t(`cms.structure.${this.isSite ? 'newSite' : 'newChapter'}`) as string

      this.step = 0
      this.selectedPreset = null
      this.presetTagIds = []

      if (this.isPreset) {
        setTimeout(() => {
          const iframes = document.getElementsByTagName('iframe')

          const iFrameDocumentDesktop = (iframes[1] as HTMLIFrameElement).contentDocument!
          const bodyDesktop = iFrameDocumentDesktop.body
          html2canvas(bodyDesktop).then((canvas: any) => {
            this.presetDesktopThumb = canvas.toDataURL('image/png')
          })

          const iFrameDocumentMobile = (iframes[2] as HTMLIFrameElement).contentDocument!
          const bodyMobile = iFrameDocumentMobile.body
          html2canvas(bodyMobile).then((canvas: any) => {
            this.presetMobileThumb = canvas.toDataURL('image/png')
          })

          this.thumbnailsGenerated = true
        }, 2000)

        if (!this.isPreset) {
          await (this.$refs.presetsPreview as any).refetch()
        }
      }
    }
  },

  methods: {
    close () {
      this.valueProxy = false
    },

    cancel () {
      this.$emit('cancel', () => { this.close() })
    },

    save () {
      this.$emit('save', {
        name: this.name,
        preset: this.selectedPreset,
        savePresetTexts: this.savePresetTexts,
        thumbnailMobile: this.presetMobileThumb,
        thumbnailDesktop: this.presetDesktopThumb,
        presetTagIds: [...this.presetTagIds]
      }, () => {
        this.close()
      })
    }
  }
})
