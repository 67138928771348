






























import Vue from 'vue'
import { ContentTree } from '../types'
import IFrameWrapper from './IFrameWrapper'
import CMSRenderer from './CMSRenderer.vue'

export default Vue.extend({
  name: 'ScreenPreview',
  components: { IFrameWrapper, CMSRenderer },

  props: {
    screen: Object as () => ContentTree,
    width: [String, Number],
    height: [String, Number]
  },

  data () {
    return {
      rendered: false
    }
  }
})
