import { ContentTree } from '../types'

export function preparePresetContent (content: ContentTree[], saveTexts: boolean) {
  content.forEach(component => {
    if (saveTexts) {
      component.texts.forEach(text => {
        if (text.id) {
          delete (text as any).id
        }
      })
    } else {
      component.texts = []
    }

    if (component.children) preparePresetContent(component.children, saveTexts)
  })

  return content
}
