





































































import Vue from 'vue'
import { GET_CONTENT_PRESETS } from '../../../graphql'
import { ContentPreset, Query } from '@simpl/core/types/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { ContentTree } from '../../../types'

type ContentTreeAndPreset = ContentTree & ContentPreset

export default Vue.extend({
  name: 'PresetsPreview',

  props: {
    selectedTagId: String,
    selectedPreset: Object as () => ContentTreeAndPreset,
    isPreset: Boolean
  },

  data () {
    return {
      presets: [] as ContentTreeAndPreset[],
      loading: 0
    }
  },

  computed: {
    filteredPresets (): ContentTreeAndPreset[] {
      return this.selectedTagId
        ? this.presets.filter(preset => preset.tags.find(tag => tag.id === this.selectedTagId))
        : this.presets
    },
    mobilePreview (): boolean {
      return this.$store.state.cms.device && this.$store.state.cms.device.width < 400
    }
  },

  apollo: {
    presets: {
      query: GET_CONTENT_PRESETS,

      fetchPolicy: 'network-only',

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        }
      },

      update (result: Query): any {
        const presets = result.contentPresets!.data

        return presets.map(p => ({
          ...p,
          children: JSON.parse(p.data as string),
          name: getTextForUserLanguage(p)
        }))
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    refetch () {
      this.$apollo.queries.presets.refetch()
    }
  }
})
