
















































































































































import Vue from 'vue'
import { ContentTree } from '../../../types'
import { capitalize, getTextForLanguage } from '@simpl/core/utils'
import { mapState } from 'vuex'
import StructureItem from './StructureItem.vue'
import SortableJS from 'sortablejs'
import { getTreeContent, getTreeContentParent } from '../../../utils/tree-operations'
import { scheduleUpdateQueue } from '@simpl/cms-components/utils/update-queue'
import { getTextForLanguageWithFallback } from '@simpl/core/utils/text'

export default Vue.extend({

  components: {
    StructureItem
  },
  inject: {
    addScreen: { default: () => {} },
    deleteContent: { default: () => {} },
    rename: { default: () => {} }
  },

  props: {
    content: Object as () => ContentTree,
    open: Boolean
  },

  data (): { _sortable?: SortableJS } {
    return {}
  },

  computed: {
    ...mapState('cms', { chapterDragActive: '_structureChapterDragActive' }),
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    title (): string {
      return getTextForLanguageWithFallback(this.content, this.$store.state.cms.currentLanguageCode, this.$store.state.cms.masterLanguageCode)
    }
  },

  mounted () {
    const moveItem = (itemId: number | string, parentId: string | number, oldIndex: number, newIndex: number) => {
      const oldParent = getTreeContentParent(this.$store.state.cms.content, itemId)!
      const newParent = getTreeContent(this.$store.state.cms.content, parentId)!

      const item = oldParent.children.splice(oldIndex, 1)[0]!
      newParent.children.splice(newIndex, 0, item)

      scheduleUpdateQueue()
    }

    const ref = this.$refs.childContainer as HTMLElement

    this._sortable = new SortableJS(ref, {
      handle: '.drag-handle',
      group: {
        name: 'sites'
      },
      setData: dataTransfer => {
        dataTransfer.setData('content/id', this.content.id)
      },
      onUpdate: evt => {
        moveItem(
          evt.item.dataset.id!,
          this.content.id,
          evt.oldDraggableIndex!,
          evt.newDraggableIndex!
        )
      },
      onAdd: evt => {
        moveItem(
          evt.item.dataset.id!,
          this.content.id,
          evt.oldDraggableIndex!,
          evt.newDraggableIndex!
        )
      }
    })
  },
  beforeDestroy () {
    this._sortable?.destroy()
  },

  methods: {
    capitalize
  }
}
)
