import Vue from 'vue'
import { ContentTree } from '@simpl/cms/types'
import { uuid } from './uuid'
import { scheduleUpdateQueue } from './update-queue'
import { Text } from '@simpl/core/types/graphql'
import { getTreeContent } from '@simpl/cms/utils/tree-operations'

export default function createChapterOrSite (
  context: Vue,
  parentId: number | string,
  type: string,
  siblingId?: number | string,
  texts: Text[] = [],
  insertBefore = false
): ContentTree {
  const parent = getTreeContent(context.$store.state.cms.content, parentId) as ContentTree
  const newContentObj = createNewElementObject(type, parent.id, texts)

  if (siblingId && !insertBefore) {
    const insertPos = parent.children.findIndex((c) => String(c.id) === String(siblingId))
    parent.children.splice(insertPos === -1 ? 0 : insertPos + 1, 0, newContentObj)
  } else if (siblingId && insertBefore) {
    const insertPos = parent.children.findIndex((c) => String(c.id) === String(siblingId))
    parent.children.splice(insertPos === -1 ? 0 : insertPos, 0, newContentObj)
  } else {
    if (!parent.children) Vue.set(parent, 'children', [])

    parent.children.push(newContentObj)
  }

  parent._changedByUser = true

  scheduleUpdateQueue()

  return newContentObj
}

function createNewElementObject (
  type: string,
  parentId: number | string,
  texts: Text[]
): ContentTree {
  return {
    id: uuid(),
    type,
    isLocal: true,
    data: null,
    parent: null!,
    children: [],
    properties: null!,
    texts
  } as ContentTree
}
