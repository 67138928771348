import { render, staticRenderFns } from "./CMSRenderer.vue?vue&type=template&id=098d6838&"
import script from "./CMSRenderer.vue?vue&type=script&lang=ts&"
export * from "./CMSRenderer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
installComponents(component, {VItemGroup})
